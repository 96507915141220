import React from 'react';
import {IcomoonReact} from "icomoon-react/build/src/IcomoonReact";
//noinspection TypeScriptCheckImport
import IconSet from "../../assets/selection.json"
import ScrollableAnchor from 'react-scrollable-anchor'


function Footer() {
    return (
        <ScrollableAnchor id={"section-contact"}>

            <section className="site-section bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <div className="section-heading text-center">
                                <h2>Interested In <strong>Working</strong> With Me?</h2>
                                <h5>Drop me a line</h5>
                            </div>
                        </div>
                        <div className="container site-footer">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <p>
                                        <a href="https://www.linkedin.com/in/danielleduijst/" target="#"
                                           className="social-item">
                                            {/*<span className="icon-linkedin2"></span>*/}
                                            <IcomoonReact className={"icon-linkedin2"} size={"22px"} color={"#ffc107"}
                                                          icon="linkedin2" iconSet={IconSet}/>

                                        </a>
                                        <a href="https://twitter.com/xSjaapie" target="#" className="social-item">
                                            <IcomoonReact className={"icon-twitter"} size={"22px"} color={"#ffc107"}
                                                          icon="twitter" iconSet={IconSet}/>
                                        </a>
                                        <a href="https://www.instagram.com/dmsduijst/" target="#"
                                           className="social-item">
                                            <IcomoonReact className={"icon-instagram2"} size={"22px"} color={"#ffc107"}
                                                          icon="instagram2" iconSet={IconSet}/>
                                        </a>
                                        <a href="https://vimeo.com/danielleduijst" target="#" className="social-item">
                                            <IcomoonReact className={"icon-vimeo"} size={"22px"} color={"#ffc107"}
                                                          icon="vimeo" iconSet={IconSet}/>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ScrollableAnchor>

    );
}

export default Footer;
