import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import danielle from "../../assets/danielle.jpg"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        marginLeft: 0
    },
    title: {
        flexGrow: 1,
    },
    appbarMain: {
        background: "url(" + danielle + ")",
        boxShadow: "none",
        backgroundSize: "cover"
    },
    sitehero: {
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        position: "relative",
        overflow: "hidden",
        height: "100vh",
        minHeight: "800px"
    }
}));

export interface NavbarOldProps {
    isMainMenu: boolean,
    color?: string
}

function NavBarOld(props: NavbarOldProps) {
    const classes = useStyles();

    let backgroundImage = props.isMainMenu ? classes.appbarMain : "";
    return (
        <div>
            <nav className={"navbar navbar-expand-lg site-navbar navbar-light bg-dark shadow"} id={"pb-navbar"}>
                <div className={"container"}>
                    <button className={"navbar-toggler d-none"} type={"button"} data-toggle={"collapse"}
                            data-target={"#navbarsExample09"}
                            aria-controls={"navbarsExample09"}
                            aria-expanded={false} aria-label={"Toggle navigation"}>
                        <span className={"navbar-toggler-icon"}></span>
                    </button>
                    <a className={"navbar-brand"} href={"index.html"}>danielle duijst</a>
                    <div className="collapse navbar-collapse justify-content-md-center" id="navbarsExample09">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/#section-portfolio">Portfolio</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/#section-about">About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/#section-contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            {
                props.isMainMenu &&

                <section className={"site-hero " + backgroundImage}
                         style={!props.isMainMenu ? {backgroundColor: props.color} : undefined} id="section-home"
                         data-stellar-background-ratio="0.5">
                    <div className="container">
                        <div className="row intro-text align-items-center justify-content-center">
                            <div className="col-md-10 text-center">
                                <h1 className="site-heading site-animate">Hi there, I'm <strong>Daniëlle Duijst</strong>
                                </h1>
                                <p className="lead site-subheading mb-4 site-animate">Passionate about creating innovative digital products that solve real problems. Welcome to my portfolio!
                                </p>
                                <p>
                                    <a href="#section-about" className="smoothscroll btn btn-warning px-4 py-3">Get to know me</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            }

        </div>
    );
}

export default NavBarOld;
