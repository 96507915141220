import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import chatbot from '../../assets/chatbot.jpg';
import warmwelkom from '../../assets/warmwelkom.jpg';
import chatterbox from '../../assets/chatterbox.jpg';
import gamification from '../../assets/gamification.png';
import realme from '../../assets/realme.jpg';
import etpa from '../../assets/etpa.jpg'
import hanno from '../../assets/hanno.jpg'
import sustainability from '../../assets/sustainability.jpg'
import dragon from '../../assets/dragon.jpg'
import {Card, CardActionArea, CardContent, CardMedia, Grid} from "@material-ui/core";
import {CardTags, CardType} from "../../models/types";
import Chip from '@material-ui/core/Chip';
import ScrollableAnchor from 'react-scrollable-anchor'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1rem',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        filter: {
            display: 'table',
            margin: 'auto',
            marginBottom: '2rem'
        },
        chip: {
            float: 'left',
            margin: '0 5px',
            listStyleType: 'none'
        },
        media: {
            maxWidth: '100 %',
            height: 'auto'
        },
        grid: {
            // marginRight: '20%',
            // marginLeft: '20%'
        }

    }),
);

function Projects() {
    const allCards = [
        {
            title: "Gamification in B2B",
            image: gamification,
            content: "Research | Knowledge Sharing Presentation",
            tag: [CardTags.UX, CardTags.PRES],
            href: "/gamification"
        },
        {
            title: "Sustainability App",
            image: sustainability,
            content: "UX Research & Design | Workshop Facilitation",
            tag: [CardTags.UX, CardTags.VISUALDESIGN],
            href: "/sustainability"
        },
        {
            title: "Warm Welkom App",
            image: warmwelkom,
            content: "UX Research & Design | Workshop Facilitation",
            tag: [CardTags.UX, CardTags.VISUALDESIGN],
            href: "/warmwelkom"
        },
        {
            title: "Personalized Chatbots",
            image: chatbot,
            content: "UX Research | Prototyping | Presentation",
            tag: [CardTags.UX,CardTags.PRES],
            href: "/chatbot"
        },
        {
            title: "Chatterbox",
            image: chatterbox,
            content: "Design Thinking Process | Prototyping",
            tag: [CardTags.UX, CardTags.PRES],
            href: "/chatterbox"
        },
        {
            title: "Realme",
            image: realme,
            content: "UX Research & Design",
            tag: [CardTags.UX, CardTags.PRES],
            href: "/realme"
        },
        {
            title: "UX Analysis ETPA",
            image: etpa,
            content: "UX Audit | Presentation",
            tag: [CardTags.UX,CardTags.PRES],
            href: "/etpa"
        },
        {
            title: "Hanno Weet Raad",
            image: hanno,
            content: "Webdevelopment | UX Recommendations",
            tag: [CardTags.DEV],
            href: "/hanno"
        },
        {
            title: "The Dragons Tragedy",
            image: dragon,
            content: "2.5D Animation | Visual Design",
            tag: [CardTags.VISUALDESIGN],
            href: "/dragon"
        }
    ];
    const classes = useStyles();
    const filters = [CardTags.ALL, CardTags.UX, CardTags.PRES, CardTags.VISUALDESIGN, CardTags.DEV];
    const [selectedTag, setSelectedTag] = useState<CardTags>(CardTags.ALL);
    const [cards, setCards] = useState<CardType[]>(allCards);

    useEffect(() => {
        if (selectedTag === CardTags.ALL) {
            setCards(allCards)
        } else {
            setCards(allCards.filter(value => value.tag.includes(selectedTag)))
        }
    }, [selectedTag])

    function getColor(value: CardTags): string {
        switch (value) {
            case CardTags.ALL:
                return selectedTag === CardTags.ALL ? "#ffc107" : "rgba(255, 193, 7, 0.3)";
            case CardTags.UX:
                return selectedTag === CardTags.UX ? "#ffc107" : "rgba(255, 193, 7, 0.3)";
            case CardTags.VISUALDESIGN:
                return selectedTag === CardTags.VISUALDESIGN ? "#ffc107" : "rgba(255, 193, 7, 0.3)";
            case CardTags.DEV:
                return selectedTag === CardTags.DEV ? "#ffc107" : "rgba(255, 193, 7, 0.3)";
            case CardTags.PRES:
                return selectedTag === CardTags.PRES ? "#ffc107" : "rgba(255, 193, 7, 0.3)";
        }
        return 'primary'
    }

    function onCardClick(page: string) {
        document.location.href = page;
    }

    return (
        <ScrollableAnchor id={"section-portfolio"}>
            <section className={"site-section bg-light"}>
                <div className={"container"}>
                    <div className={"row mb-5 align-items-center"}>
                        <div className={"col-sm-9 mx-auto"}>
                            <div className={classes.root}>
                                <div className={"section-heading text-center"}>
                                    <h2>Portfolio</h2>
                                </div>
                            </div>
                            <div className={classes.filter}>
                                <ul className="pl-0">
                                    {filters.map((tag) => {
                                        return (
                                            <li key={tag} className={classes.chip}>
                                                <Chip
                                                    label={tag}
                                                    onClick={() => setSelectedTag(tag)}
                                                    style={{backgroundColor: getColor(tag)}}
                                                />
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>

                            <Grid container spacing={3} className={classes.grid}>
                                {cards.map(value => {
                                    return <Grid item xs={12} md={4}>
                                        <Card className={"shadow"} onClick={() => onCardClick(value.href)}>
                                            <CardActionArea>
                                                <CardMedia
                                                    className={classes.media}
                                                    component="img"
                                                    alt={value.title}
                                                    height="140px"
                                                    image={value.image}
                                                    title={value.title}
                                                />
                                                <CardContent className={"text-center"}>
                                                    <Typography gutterBottom variant="h6" component="h4">
                                                        {value.title}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                        {value.content}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                })}
                            </Grid>
                        </div>
                    </div>
                </div>
            </section>
        </ScrollableAnchor>
    );
}

export default Projects;
