import React from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            top: 'auto',
            bottom: 0,
            boxShadow: '0 0px 30px 5px rgba(0,0,0, 0.3)',
            background: '#343a40',
        },
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
            marginLeft: 0
        },
        title: {
            flexGrow: 1,
        },
        smallTitle: {
            fontSize: '12px',
            letterSpacing: '.1em',
            textTransform: 'uppercase',

        }
    }),
);

export interface link {
    href: string,
    name: string
}
export interface PrefotterProps {
    previous: link,
    next: link,
}

function PreFooter(props: PrefotterProps) {
    const classes = useStyles();

    return (
        <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" href={props.previous.href}>
                    <ArrowLeftIcon />
                    <Typography className={classes.smallTitle}>{props.previous.name}</Typography>
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                </Typography>
                <IconButton edge="end" color="inherit" href={props.next.href}>
                    <Typography className={classes.smallTitle}>{props.next.name}</Typography>
                    <ArrowRightIcon />
                </IconButton>
            </Toolbar>

        </AppBar>
    );
}

export default PreFooter;
