import React from 'react';
import { withApp } from "../App";
import PreFooter from "../components/pre-fotter/prefooter.component";
import NavBarOld from "../components/navbar/navbar.old.component"; // with import
import screenshot from '../assets/hanno-detail/screenshot.png'


function Hanno() {
    let previous = { href: '/etpa', name: 'UX Analysis ETPA' }
    let next = { href: '/dragon', name: 'Dragunta animation' }

    return (
        <div>
            <NavBarOld isMainMenu={false} color={'#ceb304'} />
            <PreFooter previous={previous} next={next} />
            <section className={"site-section sub-page"}>
                <div className={"container"}>
                    <div className={"row align-items-center"}>
                        <div className={"col-sm-10 mx-auto"}>
                            <h1>Hanno Weet Raad - Webdevelopment</h1>
                            <p className={"lead"}>
                                Hanno asked Social Brothers to develop a website for his company.
                                I developed the responsive website in collaboration with the designer,
                                always keeping the UX in mind.
                                
                            </p>
                            <p>
                                Technologies used:
                                <ul>
                                    <li>Wordpress</li>
                                    <li>MySQL</li>
                                    <li>Bootstrap</li>
                                    <li>jQuery</li>
                                    <li>PHP</li>
                                    <li>Google Analytics</li>
                                </ul>

                            </p>
                            <a target={"_blank"} href={"https://hannoweetraad.nl"}>
                                <img src={screenshot} alt={"screenshot website"} className={"w-100"} />
                            </a>




                        </div>
                    </div>
                </div>
            </section>
            <PreFooter previous={previous} next={next} />
        </div>
    );
}

export default withApp(Hanno);
