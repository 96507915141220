import React from 'react';
import {withApp} from "../App";
import NavBarOld from "../components/navbar/navbar.old.component";
import PreFooter from "../components/pre-fotter/prefooter.component"; // with import

function Dragon() {
    let previous = {href: '/hanno', name: 'Hanno Website'}
    let next = {href: '/sustainability', name: 'Sustainability App'}

    return (
        <div>
            <NavBarOld isMainMenu={false} color={'#123456'}/>
            <PreFooter previous={previous} next={next}/>

            <section className={"site-section sub-page"}>
                <div className={"container"}>
                    <div className={"row mb-5 align-items-center"}>
                        <div className={"col-sm-8 mx-auto"}>

                            <h1>
                                Dragunta - 2.5D animation
                            </h1>
                            <p className={"lead"}>
                                For the course animation principles, we had to make a 2.5D animation from a children's story book.
                                I chose for a Maltese story book about a dragon, a prince and a princess.
                                I photocopied all the interesting pages and created extra layering in photoshop.
                                Then I used Adobe After Effects to animate the different layers.
                            </p>

                            <h2>The Video</h2>
                            <p>
                                <div className={"embed-responsive embed-responsive-16by9"}>
                                    <iframe src={"https://player.vimeo.com/video/125246878"} width={"640"}
                                            height={"360"} allow={"autoplay; fullscreen"}></iframe>
                                </div>
                            </p>

                            <h2>Narrative Dragunta Story</h2>
                            <strong>Scene 1</strong>
                            <p>
                                Once upon a time there was a king with a beautiful daughter, she only wanted to get married with a guy with golden teeth, because she didn’t knew anybody with golden teeth.
                            </p>
                            <strong>Scene 2</strong>
                            <p>
                                One day a young man with golden teeth passed and he married the princess. He took her to a cave.
                            </p>
                            <strong>Scene 3</strong>
                            <p>At night the young man transformed into a monsterous dragon. The dragon prisoned her.
                            </p>

                            <strong>Scene 4</strong>
                            <p>
                                The princess sended pigeons to her father with a letter telling him that she is imprisoned.
                            </p>

                            <strong>Scene 5</strong>
                            <p>
                                The king sent brave man to save her, he punched the doors to the cave to get to the princess. One man was very strong.

                            </p>

                            <strong> Scene 6 </strong>
                            <p>
                                They saved the princess and the princess married the man who killed the dragon. They lived happily ever after.
                            </p>


                            <h2>Lessons learned</h2>
                            <p>
                                <ul>
                                    <li>Never try to render and work in 4K quality on a four year old Macbook
                                    </li>
                                    <li>
                                        If for example an arm moves away from the body, make sure you create a layer behind the arm. Otherwise you see a gap.
                                    </li>
                                    <li>
                                        Make sure you find good images/scans/colors/textures so that it matches as a whole
                                    </li>
                                    <li>
                                        2.5D animations are relatively easy to make and have a nice magical effect
                                    </li>
                                    <li>
                                        Never try to translate Maltese without help, unless you know Arabic and Italian.
                                    </li>
                                </ul>
                            </p>

                        </div>
                    </div>
                </div>
            </section>


            <PreFooter previous={previous} next={next}/>
        </div>
    );
}

export default withApp(Dragon);
