import React from 'react';
import {withApp} from "../App";
import PreFooter from "../components/pre-fotter/prefooter.component";
import NavBarOld from "../components/navbar/navbar.old.component"; // with import

import saimage from "../assets/sa-cs.png"


function Sustainability() {
    let previous = {href: '/dragon', name: 'Dragunta animation'}
    let next = {href: '/gamification', name: 'Gamification for B2B'}

    return (
        <div>
            <NavBarOld isMainMenu={false} color={'#aabb23'}/>
            <PreFooter previous={previous} next={next}/>

            <section className={"site-section"}>
                <div className={"container"}>
                    <div className={"row align-items-center"}>
                        <div className={"col-sm-10 mx-auto"}>
                            <h1>Sustainability App</h1>
                            <h2>The Prototype</h2>
                            <p>
                                <iframe title="sustainability" className={"border-0"} width={"100%"} height={"600"} src={"https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FNuI481skBEYTp9MSnaZ45L%2FUX-Portfolio%3Fpage-id%3D116%253A367%26node-id%3D386-448%26viewport%3D542%252C914%252C0.36%26t%3D1rJgVMNIdBWqCIzL-1%26scaling%3Dscale-down%26starting-point-node-id%3D386%253A448"} allowFullScreen></iframe>
                            </p>
                            <h2>
                                The Case Study
                            </h2>
                            <p>
                            <img src={saimage} alt={"Sustainability app casestudy"} className={"m-auto d-block w-100"}/>
                            </p>

                        </div>
                    </div>
                </div>
            </section>


            <PreFooter previous={previous} next={next}/>
        </div>
    );
}

export default withApp(Sustainability);
