import React from 'react';
import {withApp} from "../App";
import PreFooter from "../components/pre-fotter/prefooter.component";
import NavBarOld from "../components/navbar/navbar.old.component"; // with import

import saimage from "../assets/sa-cs.png"


function Gamification() {
    let previous = {href: '/sustainability', name: 'Sustainability App'}
    let next = {href: '/warmwelkom', name: 'Warm Welkom App'}

    return (
        <div>
            <NavBarOld isMainMenu={false} color={'#aabb23'}/>
            <PreFooter previous={previous} next={next}/>

            <section className={"site-section"}>
                <div className={"container"}>
                    <div className={"row align-items-center"}>
                        <div className={"col-sm-10 mx-auto"}>
                            <h1>Gamification for B2B Presentation</h1>
                            <p className={"lead"}> For a UX knowledge sharing session, I conducted in-depth research on the gamification framework Octalysis within a business context and shared my findings in a company-wide presentation. My goal was to inspire colleagues and encourage innovative, out-of-the-box thinking. 
                            </p>
                            <p>
                            Explore the slides below to learn more about the gamification design process, the Octalysis framework, and how this framework can be used for exploring new features.
                            </p>
                            <iframe title="gamification" className={"border-0"} width={"100%"} height={"600"} src={"https://docs.google.com/presentation/d/e/2PACX-1vQ1Z_KM6p-GqoGUMncAnRKN7LheGEBgfHuFxH0_eCdh5lVaM8LmuVWEui9Y99wwWu2WmhCGiXZiR_P4/embed?start=false&loop=false&delayms=3000"} allowFullScreen></iframe>

                        </div>
                    </div>
                </div>
            </section>


            <PreFooter previous={previous} next={next}/>
        </div>
    );
}

export default withApp(Gamification);
