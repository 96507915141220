import React from 'react';
import Typography from "@material-ui/core/Typography";
import {withApp} from "../App";
import NavBar from "../components/navbar/navbar.component";
import {blue} from "@material-ui/core/colors";
import PreFooter from "../components/pre-fotter/prefooter.component";
import NavBarOld from "../components/navbar/navbar.old.component"; // with import
import wwimage from "../assets/ww-cs.png"


function WarmWelkom() {
    let previous = {href: '/gamification', name: 'Gamification for B2B'};
    let next = {href: '/chatbot', name: 'UX of Chatbots'};

    return (
        <div>
            <NavBarOld isMainMenu={false} color={''}/>
            <PreFooter previous={previous} next={next}/>


            <section className={"site-section sub-page"}>
                <div className={"container"}>
                    <div className={"row align-items-center"}>
                        <div className={"col-sm-10 mx-auto"}>
                            <h1>Warm Welkom App</h1>

                            <h2> The Case Study </h2>
                            <p>
                             <img src={wwimage} alt={"warm welkom casestudy"} className={"m-auto d-block w-100"}/>
                            </p>
                            <h2>Adding a new customer flow</h2>
                            <p>

                                <iframe className={"border-0"} width={"100%"} height={"600"} src={"https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FNuI481skBEYTp9MSnaZ45L%2FUX-Portfolio%3Fpage-id%3D116%253A264%26node-id%3D159-0%26viewport%3D474%252C557%252C0.22%26t%3D9ddSdOuSBx6sLAZd-1%26scaling%3Dcontain"} allowFullScreen></iframe>

                            </p>
                        </div>
                    </div>
                </div>
            </section>


            <PreFooter previous={previous} next={next}/>
        </div>

    );
}

export default withApp(WarmWelkom);
