import React from 'react';
import {withApp} from "../App";
import Projects from "../components/projects/projects.component";
import About from "../components/about/about.component";
import NavBarOld from "../components/navbar/navbar.old.component";

function HomeView() {
    return (
        <div>
            <NavBarOld isMainMenu={true}/>
            <Projects/>
            <About/>
        </div>
    );
}

export default withApp(HomeView);
