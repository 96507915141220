import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import goldenCircle from "../../assets/golden_circle.png"
import "../../styles/css/bootstrap.css"
import "../../styles/css/style.css"
import ScrollableAnchor from 'react-scrollable-anchor'


function About() {

    return (
        <ScrollableAnchor id={"section-about"}>

            <section className={"site-section"}>
                <div className={"container"}>
                    <div className={"row mb-5 align-items-center"}>
                        <div className="col-lg-7 pr-lg-5 mb-5 mb-lg-0">
                            <img src={goldenCircle} alt={"Image placeholder"} className={"img-fluid"} />
                        </div>
                        <div className={"col-lg-5 pl-lg-5"}>
                            <div className="section-heading">
                                <h2>About <strong>Me</strong>
                                </h2>
                            </div>
                            <p className="lead">I am a Dutch UX Researcher &amp; Designer with 6+ years of experience, including (agile) front-end development. Specializing in strategic UX research, I use qualitative and quantitative methods to drive improvements and empower innovation in digital solutions.</p>
                            <p className={"mb-5"}>
                            Originally from a small Dutch fishermen's town, I earned a Master of Science in Information Studies (Human-Centered Multimedia). Currently based in sunny Malta, I thrive at the intersection of user experience, technology, and research, with a strong affinity for innovation. My goal is to align research findings with business objectives to create impactful solutions for end-users.
                                <br/>
                                <br/>
                                Explore my portfolio to see how my analytical, technical, and creative strengths can bring value to your team.
                            </p>


                            <p>
                                <a href="#section-contact"
                                    className="smoothscroll btn btn-warning px-4 py-2 mr-2">Contact Me</a>
                                <a href="https://github.com/xDanielle/portfolio/raw/master/portfolio/images/public%20CV.pdf"
                                    className="btn btn-secondary px-4 py-2" download>Resume</a>
                            </p>
                        </div>
                    </div>

                    <div className="row pt-5">
                        <div className="col-md-3 mb-3">
                            <div className="section-heading">
                                <h2>My <strong>Skills</strong>
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-9">
    <div className="skill">
        <h3>UX Research</h3>
        <div className="progress">
            <div className="progress-bar" role="progressbar" style={{ width: "90%" }}
                aria-valuenow={90}
                aria-valuemin={0} aria-valuemax={100}>
                <span>Advanced</span>
            </div>
        </div>
    </div>
    <div className="skill">
        <h3>Communication and Collaboration</h3>
        <div className="progress">
            <div className="progress-bar" role="progressbar" style={{ width: "85%" }}
                aria-valuenow={85}
                aria-valuemin={0} aria-valuemax={100}>
                <span>Advanced</span>
            </div>
        </div>
    </div>
    <div className="skill">
        <h3>Human-Centered Design and Design Thinking</h3>
        <div className="progress">
            <div className="progress-bar" role="progressbar" style={{ width: "85%" }}
                aria-valuenow={80}
                aria-valuemin={0} aria-valuemax={100}>
                <span>Advanced</span>
            </div>
        </div>
    </div>
    
    <div className="skill">
        <h3>Workshop Facilitation</h3>
        <div className="progress">
            <div className="progress-bar" role="progressbar" style={{ width: "70%" }}
                aria-valuenow={75}
                aria-valuemin={0} aria-valuemax={100}>
                <span>Proficient</span>
            </div>
        </div>
    </div>
    <div className="skill">
        <h3>Product Analysis and Strategy</h3>
        <div className="progress">
            <div className="progress-bar" role="progressbar" style={{ width: "65%" }}
                aria-valuenow={75}
                aria-valuemin={0} aria-valuemax={100}>
                <span>Proficient</span>
            </div>
        </div>
    </div>
    <div className="skill">
        <h3>Agile and Scrum Methodologies</h3>
        <div className="progress">
            <div className="progress-bar" role="progressbar" style={{ width: "65%" }}
                aria-valuenow={70}
                aria-valuemin={0} aria-valuemax={100}>
                <span>Proficient</span>
            </div>
        </div>
    </div>
    <div className="skill">
        <h3>Emerging Technologies and Innovation</h3>
        <div className="progress">
            <div className="progress-bar" role="progressbar" style={{ width: "60%" }}
                aria-valuenow={65}
                aria-valuemin={0} aria-valuemax={100}>
                <span>Proficient</span>
            </div>
        </div>
    </div>
    <div className="skill">
        <h3>Front-End Development</h3>
        <div className="progress">
            <div className="progress-bar" role="progressbar" style={{ width: "50%" }}
                aria-valuenow={60}
                aria-valuemin={0} aria-valuemax={100}>
                <span>Competent</span>
            </div>
        </div>
    </div>
</div>
                    </div>
                </div>
            </section>
        </ScrollableAnchor>
    );
}

export default About;
