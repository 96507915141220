import React from 'react';
import './App.css';
import Footer from "./components/footer/footer.component";

function App(props) {
    return (
        <div>
            {props.children}
        </div>
);
}

export function withApp(WrappedComponent) {
    return () => <div><App><WrappedComponent/></App> <Footer/></div>
}

export default App;
