import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {UIRouter, UIView} from "@uirouter/react";
import HomeView from "./views/Home.view";
import {pushStateLocationPlugin} from "@uirouter/core";
import ChatBot from "./views/Chatbot.view";
import ChatterBox from "./views/Chatterbox.view";
import Dragon from "./views/Dragons.view";
import ETPA from "./views/Etpa.view";
import Realme from "./views/Realme.view";
import Hanno from "./views/Hanno.view";
import WarmWelkom from "./views/WarmWelkom.view"
import Sustainability from "./views/Sustainability.view"
import Gamification from './views/Gamification.view';


const states = [
  {
    name: 'home',
    url: '/index.html',
    component: HomeView,
  },
  {
    name: 'home2',
    url: '/',
    component: HomeView,
  },
  {
    name: 'chatbot',
    url: '/chatbot',
    component: ChatBot
  },
  {
    name: 'chatterbox',
    url: '/chatterbox',
    component: ChatterBox
  },
  {
    name: 'dragon',
    url: '/dragon',
    component: Dragon
  },
  {
    name: 'ETPA',
    url: '/etpa',
    component: ETPA
  },
  {
    name: 'Realme',
    url: '/realme',
    component: Realme
  },
  {
    name: 'Hanno',
    url: '/hanno',
    component: Hanno
  },
  {
    name: 'Warmwelkom',
    url: '/warmwelkom',
    component: WarmWelkom
  },
  {
    name: 'Gamification',
    url: '/gamification',
    component: Gamification
  },
  {
    name: 'Sustainability',
    url: '/sustainability',
    component: Sustainability
  }
];

const plugins = [pushStateLocationPlugin];

ReactDOM.render(
  <React.StrictMode>
    <UIRouter plugins={plugins} states={states}>
      <UIView/>
      <App />
    </UIRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
