import React from 'react';
import Typography from "@material-ui/core/Typography";
import {withApp} from "../App";
import NavBar from "../components/navbar/navbar.component";
import {blue} from "@material-ui/core/colors";
import PreFooter from "../components/pre-fotter/prefooter.component";
import NavBarOld from "../components/navbar/navbar.old.component"; // with import

import process from '../assets/etpa-detail/process.png'
import ia from '../assets/etpa-detail/ia.png'


function ETPA() {
    let previous = {href: '/realme', name: 'RealMe'}
    let next = {href: '/hanno', name: 'Hanno Website'}

    return (
        <div>
            <NavBarOld isMainMenu={false} color={'#ce049f'}/>
            <PreFooter previous={previous} next={next}/>

            <section className={"site-section sub-page"}>
                <div className={"container"}>
                    <div className={"row mb-5 align-items-center"}>
                        <div className={"col-sm-8 mx-auto"}>

                            <h1>UX Analysis ETPA</h1>
                            <p className={"lead"}>
                                ETPA is an energy trading company. They asked me to analyse the UX and UI of their platform
                                and propose improvements and recommendations.
                            </p>

                            <blockquote className={"blockquote"}>
                                "Very well summarised!" - client feedback about the report
                            </blockquote>

                            <h2>The process</h2>
                            <p>
                                <img src={process} alt={"process"} className={"w-75"}/>
                            </p>

                            <h2>
                                Business Goals
                            </h2>
                            <p>
                                Speaking with the business to identify the current problems and opportunities of the system.</p>

                            <h2>User Goals</h2>
                            <p>Analysing documentation, observing meetings with stakeholders and users, and synthesising
                            the information in an overview. I also created persona's of the different groups of end-users
                            to give insight in the goals and expectations of different user groups.</p>

                            <h2>User Journey Mapping</h2>
                            <p>
                                Next up was analysing the current user flow, to identify painpoints in the current flow.
                                I proposed recommendations for every user journey.
                            </p>

                            <h2>Information Architecture</h2>
                            <p>
                                An Information Architecture was created to have a clear overview of what type of information
                                can be found where on the platform. I categorized the types of information (e.g. trades, my orders)
                                and color coded them. After removing the double information, I proposed a revised Information Architecture.
                                <br/>
                                <img src={ia} alt={"information architecture color coded blocks"} className={"w-50"}/>

                            </p>

                            <h2>Conclusions</h2>
                            <p>A recommendation report was delivered. In this report I proposed improvements for the:
                               </p>
                            <ul>
                                    <li>Information architecture</li>
                                    <li>Interaction Design</li>
                                    <li>UI Design</li>
                                </ul>
                            <p>
                                The improvements were prioritised based on their impact and categorized based on their source.
                            </p>


                        </div>
                    </div>
                </div>
            </section>


            <PreFooter previous={previous} next={next}/>
        </div>
    );
}

export default withApp(ETPA);
