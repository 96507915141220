import React from 'react';
import {withApp} from "../App";
import PreFooter from "../components/pre-fotter/prefooter.component";
import NavBarOld from "../components/navbar/navbar.old.component"; // with import
import mock1 from '../assets/realme-detail/mock-1.png'
import happyflow from '../assets/realme-detail/happyflow.png'
// import mock2 from '../assets/realme-detail/mock-2.png'
// import mock3 from '../assets/realme-detail/mock-3.png'
// import mock4 from '../assets/realme-detail/mock-4.png'



function Realme() {
    let previous = {href: '/chatterbox', name: 'Chatterbox'}
    let next = {href: '/etpa', name: 'ETPA UX Analysis'}

    return (
        <div>
            <NavBarOld isMainMenu={false} color={'#2e8708'}/>
            <PreFooter previous={previous} next={next}/>

            <section className={"site-section sub-page"}>
                <div className={"container"}>
                    <div className={"row mb-5 align-items-center"}>
                        <div className={"col-sm-8 mx-auto"}>

                            <h1>RealMe: Digital Tool for Prisoners to Develop Employability Skills</h1>
                            <p className={"lead"}>
                                For the Interaction Design Project at the University of Amsterdam, we collaborated with
                                two organisations and prisons in the Netherlands. We designed, implemented, and evaluated
                                a system in a group of 5 students. <a target="_blank"
                                                                      href="https://www.researchgate.net/publication/322207954_RealME_A_Digital_Tool_for_Prisoners_to_Develop_Employability_Skills">
                                The research paper can be found here. </a>
                                <br/>
                                Below, you can find the trailer about our process and system. Furthermore, you can
                                find the description of our proposed solution in more detail.
                            </p>

                            <h2>The Challenge</h2>
                            <p>
                                The percentage of ex-prisoners that go back to prison within two year is 47%. An important
                                reason for their recidivism is their inability to find a job when they are released.
                                When they are in prison they lose touch with society and needed knowledge. Also they
                                struggle with the social barriers when trying to find a job after their prison time.
                                Therefore, it is very important to offer them employability empowerment interventions
                                while in prison.
                            </p>
                            <p>
                                Our challenge focused on preventing prisoners from falling back into their old habits
                                by empowering their employability skills and preparing them for their first 3 months after prison.
                            </p>

                            <h2>My contribution</h2>
                            <div>
                                My contribution to this project consisted of the following elements:
                                <ul>
                                    <li>Creating a stakeholder Analysis</li>
                                    <li>Gathering requirements from the client</li>
                                    <li>Steering the design and vision of the product</li>
                                    <li>Writing for the scientific paper</li>
                                    <li>Selecting the appropriate user testing methods:
                                        <ul>
                                            <li>Semi-Structured interviews</li>
                                            <li>Think-aloud method</li>
                                            <li>TAM Questionnaire (measures ease of use and perceived usefulness)</li>
                                        </ul>
                                    </li>
                                    <li>Creating user flows</li>
                                </ul>
                            </div>

                            <h2>Video about our Interaction design process </h2>
                            <div>
                                <div className={"embed-responsive embed-responsive-16by9"}>
                                    <iframe className={"embed-responsive-item"}
                                            src={"https://www-ccv.adobe.io/v1/player/ccv/ScFL_APvExt/embed?api_key=behance1&bgcolor=%23191919"}
                                            allowFullScreen></iframe>
                                </div>
                            </div>


                            <h2>The Process</h2>
                            <div> We gathered requirements from the different organisations involved with prisons. We defined
                                the problem and created a happy flow for our system. We created a prototype and tested this
                                with different prisoners to gather feedback and improve the solution.

                                <img src={happyflow} alt={"happy flow of the system"} className={"w-100 my-3"}/>

                            </div>

                            <h2>Empathise</h2>
                            <div>
                                We did requirement gathering with the institutes that had worked with prisons and prisoners
                                for a long time. They had the following requirements:
                                <ul>
                                    <li>Help prisoners find work in the first 3 months after prison</li>
                                    <li>Adaptable to every prison in the Netherlands</li>
                                    <li>Digital, sustainable, modular</li>
                                    <li>Reporting on results</li>
                                    <li>Feasible immediate implementation</li>
                                </ul>

                                Also we interviewed the prisoners to find out what their view on the problem is and what they need.
                                The prisoners we spoke with had the following requirements:
                                <ul>
                                    <li>Clear and direct impact on their life</li>
                                    <li>Practical outcome</li>
                                    <li>Increase ability to get a job</li>
                                    <li>System that is easy and simple to understand</li>
                                </ul>
                            </div>

                            <h2>Define</h2>
                            <div>
                                We defined the problem and focused on how we can increase the employability skills of prisoners
                                while their still in prison. In that way we can support them to prepare when they get out.
                                The design goal we created was:
                                <ul>
                                    <li>Create a Digital Tool for physical engagement</li>
                                    <li>Provide Courses &amp; tasks for practical jobs</li>
                                    <li>Provide vital information about professions</li>
                                    <li>Provide feedback to engage the prisoners</li>
                                </ul>
                            </div>

                            <h2>Prototype</h2>
                            <div>
                                We created an interactive prototype immediately, because we couldn't test a lot with different
                                prisoners. It was difficult to plan multiple user tests with the prisoners. Therefore we decided
                                that we wanted to create an interactive high-fidelity prototype to gather as much as feedback as
                                possible.

                                <img src={mock1} alt={"overview realme"} className={"w-100 my-3"}/>
                                {/*<img src={mock2} alt={"painter page"} className={"w-100"}/>*/}
                                {/*<img src={mock3} alt={"painter page"} className={"w-100"}/>*/}
                                {/*<img src={mock4} alt={"painter page"} className={"w-100"}/>*/}


                            </div>

                            <h2>Testing &amp; Feedback</h2>

                            <h4>Client Feedback</h4>
                            <p>
                                The client was positive about the system that we created, was interested in engaging more with companies
                                in finding career opportunities for the ex-prisoners and they liked the simple and clean design.
                            </p>

                            <h4>User Testing</h4>
                            <div>
                                We conducted a user test with the prisoners and asked them to Think-Aloud. We also interviewed them
                                and let them fill in a questionnaire with questions from the 'Technology Acceptance Model' which measures
                                ease of use and perceived usefulness.

                                <blockquote className={"blockquote"}>
                                    "Use ex-prisoners with a success story to make the video about" - anonymous prisoner
                                    <hr/>
                                    "I like the clean lay-out and design of the website" - anonymous prisoner
                                </blockquote>
                            </div>


                            <h2>Next Steps</h2>
                            <div>
                                The next steps for this project would be the following:
                                <ul>
                                    <li>Expand the jobs section with available jobs at different companies, so the prisoners have a goal
                                to work towards to in prison.
                                    </li>
                                    <li>Give the prisoners certificates when they complete (e-learning) courses. </li>
                                    <li>Create success stories of other prisoners to add in the system, so prisoners get more motivated and get persuaded that
                                    the system can actually help them.
                                    </li>
                                </ul>
                            </div>

                            {/*<img src={poster} alt={"poster"} className={"w-100"}/>*/}


                        </div>
                    </div>
                </div>
            </section>


            <PreFooter previous={previous} next={next}/>
        </div>
    );
}

export default withApp(Realme);
