import React from 'react';
import {withApp} from "../App";
import PreFooter from "../components/pre-fotter/prefooter.component";
import NavBarOld from "../components/navbar/navbar.old.component"; // with import
import slide1 from '../assets/chatterbox-detail/1.jpg';
// import slide2 from '../assets/chatterbox-detail/2.jpg'
import slide3 from '../assets/chatterbox-detail/3.jpg'
// import slide4 from '../assets/chatterbox-detail/4.jpg'
import slide5 from '../assets/chatterbox-detail/5.jpg'
import slide6 from '../assets/chatterbox-detail/6.jpg'
// import slide7 from '../assets/chatterbox-detail/7.jpg'
import slide8 from '../assets/chatterbox-detail/8.jpg'
import slide9 from '../assets/chatterbox-detail/9.jpg'
import slide10 from '../assets/chatterbox-detail/10.jpg'
import slide11 from '../assets/chatterbox-detail/11.jpg'
import slide12 from '../assets/chatterbox-detail/12.jpg'
// import slide13 from '../assets/chatterbox-detail/13.jpg'
import slide14 from '../assets/chatterbox-detail/14.jpg'

function ChatterBox() {
    let previous = {href: '/chatbot', name: 'UX of Chatbots'}
    let next = {href: '/realme', name: 'RealMe'}

    return (
        <div>
            <NavBarOld isMainMenu={false} color={'#095954'}/>
            <PreFooter previous={previous} next={next}/>

            <section className={"site-section sub-page"}>
                <div className={"container"}>
                    <div className={"row mb-5 align-items-center"}>
                        <div className={"col-sm-8 mx-auto"}>


                            <h1>Chatterbox - A Design Thinking Case Study</h1>
                            <p className={"lead"}>
                                For the course Innovation &amp; Design Thinking at the University of Amsterdam, we used
                                the Design Thinking method to solve a problem for the communications department of the
                                Utrecht Medical Centre (UMC).
                                <a target="_blank"
                                   href={'https://drive.google.com/file/d/0B5apQCBnP0lNMGotQTlWd3B6LUk/view'}> Read the
                                report here. </a>
                                Our project is also mentioned in the book:
                                <a target="_blank"
                                   href={'https://phavi.umcs.pl/at/attachments/2019/0118/095609-dt-uni16012019-druk.pdf'}> Best Interdisciplinarity-Enhancing Practices at Higher Education Institutions</a>
                                (p. 37 - 43).
                            </p>

                            <h2>The Challenge</h2>
                            <p>
                                The main objective in this project was to establish better connection between all citizens
                                of Utrecht and the UMC in an inclusive and equal way.
                            </p>

                            <h2>The Team</h2>
                            <p>
                                The team consisted out of four Human Centered Multimedia students and one Game Studies
                                student. Each member had a different background which brought different views and insights
                                to the design process.
                            </p>


                            <h2>The Process</h2>
                            <p>
                                We worked with the Design Thinking approach, because with Design Thinking you explore the
                                problem space extensively. By going through this process, we can create a meaningful and
                                user-centered solution.
                            </p>
                            <img src={slide1} alt={"Slide1"} className={"w-100 my-3"}/>

                            <h2>Empathize</h2>
                            <p>
                                In this phase you empathize with the end users and in our case the involved
                                parties. We had several meetings with them to investigate the problem more in depth.
                                This is the phase of observing, engaging and immersing.
                                {/*<img src={slide2} alt={"Slide2"} className={"w-100"}/>*/}
                                <img src={slide3} alt={"Slide3"} className={"w-100 my-3"}/>

                            </p>


                            <h2>Define</h2>
                            <p>
                                In the Define phase, we wrote down the different point of view statements of the UMC.
                                Then we pinned down one point of view we wanted to focus on.
                                <img src={slide5} alt={"Slide5"} className={"w-100 my-3"}/>
                                <img src={slide6} alt={"Slide6"} className={"w-100 my-3"}/>
                            </p>


                            <h2>Ideate</h2>
                            <p>
                                In the ideation phase we were focusing on coming up with different solutions for the
                                point of view that we chose before. In this phase we generated as much innovative and crazy
                                ideas as possible. Afterwards, we selected these three ideas to continue with.
                                {/*<img src={slide7} alt={"Slide7"} className={"w-100"}/>*/}
                                <img src={slide8} alt={"Slide8"} className={"w-100 my-3"}/>

                                We also created a list of design requirements for the possible solution, based on the
                                information we gathered in the empathize phase. We used this list to assess the possible
                                solutions.
                                <img src={slide9} alt={"Slide9"} className={"w-100 my-3"}/>

                            </p>


                            <h2>Prototype</h2>
                            <p>
                                We decided that all three ideas didn't fulfill all the design requirements. Our main
                                challenge was that we didn't want to create a solution that people have to download themselves,
                                because we expected that they wouldn't use the solution then. We wanted to reach the people
                                in their daily lives. For example, in the shopping mall where they come everyday.

                                Therefore, we did another ideation session and we came up with another idea. Instead of
                                a digital solution, we chose to create an analogue solution that could be placed on
                                markets, in shopping malls and other public spaces. We prototyped the solution by using
                                a 3D printer.

                                <img src={slide10} alt={"Slide10"} className={"w-100 my-3"}/>
                                <img src={slide11} alt={"Slide11"} className={"w-100 my-3"}/>

                                With the Chatterbox, citizens of Utrecht can answer a question or discuss a statement.
                                Different type of people can interact with this chatterbox, because it's in an offline
                                setting and in a public place. Therefore, this is an inclusive solution. Also, the solution
                                is very visible in public spaces which is good for the promotion of the UMC.

                            </p>
                            <p>
                                The questions can be about certain topics or can have different themes.
                                Then a video will be edited and created, so that the UMC can show that e.g. on their
                                narrowcasting screens in the hospital. We created a timeline which describes the different
                                tasks that all the involved parties have to complete to make this solution work.
                                <img src={slide12} alt={"Slide12"} className={"w-100 my-3"}/>

                            </p>

                            <h2>Test</h2>
                            <p>
                                In the test phase we wanted to learn more about the user and refine our solution.
                                We went to one of the most internation and busy markets in Amsterdam to guerilla test
                                with potential. We also interviewed an expert who worked
                                on a similar concept. We incorporated the feedback in our solution and proposal that we
                                wrote for Utrecht Medical Center.
                                {/*<img src={slide13} alt={"Slide13"} className={"w-100"}/>*/}
                                <img src={slide14} alt={"Slide14"} className={"w-100 my-3"}/>
                            </p>

                            <h2>Next steps</h2>
                            <p>
                                This project was concluded in two months, therefore we did not have the time to build the chatterbox
                                and test it in real life. The interviews we did on the Dappermarket were not sufficient
                                enough to validate this solution. Therefore, the next steps would be to organise a pilot
                                in which a physical chatterbox is placed in a public space. In this way people can interact
                                with the prototype, understand the concept more and better feedback can be gathered.
                            </p>

                        </div>
                    </div>
                </div>
            </section>


            <PreFooter previous={previous} next={next}/>
        </div>
    );
}

export default withApp(ChatterBox);
